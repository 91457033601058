import React from "react";
import BootstrapNavbar from "./components/BootstrapNavbar";

function App() {
  return (
    <div className="App">
      <BootstrapNavbar />
    </div>
  );
}
export default App;
